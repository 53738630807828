export enum RouteName {
  HOME = 'assets-home',
  EXPORT = 'assets-export',
  EXPORTS_TABLE = 'assets-exports-table',
  DETAILS = 'asset-details',
  EDIT = 'asset-edit',
  COPY = 'asset-copy',
}

export enum QueryKey {
  ASSETS = 'assets',
  ASSETS_FILTERED = 'assets-filtered',
  ASSET_COMMENTS = 'asset-comments',
  ASSET_HISTORY = 'asset-history',
  LINKED_ISSUES = 'linked-issues',
  ATTACHMENTS = 'asset-attachments',
  EXPORTS = 'assets-exports',
}

export enum ExportQueryParams {
  ASSET_TYPES = 'assetTypes',
  LOCATIONS = 'locations',
  ASSET_FIELDS = 'assetFields',
}
