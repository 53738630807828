import type { RouteRecordRaw } from 'vue-router';
import { RouteName } from './constants';

export const routes: RouteRecordRaw[] = [
  {
    path: '/assets/export',
    name: RouteName.EXPORT,
    component: () => import('./views/ExportAssetsView'),
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
  {
    path: '/assets/export/table',
    name: RouteName.EXPORTS_TABLE,
    component: () => import('./views/ExportsTableView'),
    meta: { atlaskit: true, appWrapper: true },
  },
  {
    path: '/assets-new/:id',
    name: RouteName.DETAILS,
    component: () => import('./views/DetailsView'),
    props: true,
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
  {
    path: '/assets-new/:id/edit-new',
    name: RouteName.EDIT,
    component: () => import('./views/EditView'),
    props: true,
    meta: { atlaskit: true, contained: true },
  },
  {
    path: '/assets-new/:id/copy',
    name: RouteName.COPY,
    component: () => import('./views/CopyView'),
    props: true,
    meta: { atlaskit: true, appWrapper: true, contained: true },
  },
];
